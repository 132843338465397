@import '../utils/styles.css';

.studio-page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: var(--APP-BACKGROUND);
    align-items:center;
}

.studio-top-bar {
    display: flex;
    justify-content: center;
    width: 100%;
}

.studio-top-bar-mobile {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    padding-left: 10px;
    align-items: center;
}

.studio-page-heading {
    display: inline-block;
    font-family: var(--HEATERS);
    font-size: 130px;
    color: var(--LIGHT-BLUE);
    line-height: 65%;
    margin-top: 40px;
    transition: transform 1s ease-in, padding-left 1s ease-in;
    justify-content: center;
    padding-left: 0px;
}

.studio-page-heading-mobile {
    display: flex;
    font-family: var(--HEATERS);
    font-size: 80px;
    color: var(--LIGHT-BLUE);
    justify-content: center;
}

.slide {
    transform: translateX(-50vw); 
    padding-left: 520px;
}


.studio-row {
    margin-top: 2%;
}

.selected {
    border: 1px solid var(--LIGHT-BLUE);
}


.bottom-buttons-holder{
    display: flex;
    justify-content: flex-start;
    padding-left: 8px !important;
}

.bottom-button {
    height: 50px;
    width: 300px;
    margin-right: 20px;
    font-family: var(--PROXIMA-NOVA-BOLD);
    font-size: 25px;
    background-color: transparent;
    border: 1px solid;
    color: var(--LIGHT-BLUE);
    border-radius: 8px !important;
    padding-top: 4px;
    text-transform: uppercase;
}

.save-button-mobile {
    display: flex;
    justify-content: center;
}

.bottom-button:hover {
    opacity: 0.5;
}

.studio-stage {
    display: flex;
    justify-content: center;
}

@media (max-width: 768px){
    .bottom-button {
        margin-right: 0px;
    }

    .studio-stage {
        height: 450px
    }
}

.garment-tabs-holder{
    display: flex;
    justify-content: center;
}

.design-image{
    width: 100%;
}

.scrollable-container {
    height: 500px; 
    overflow-y: auto;
}

.scrollable-container-mobile {
    overflow-x: auto; 
    white-space: nowrap; 
    scroll-snap-type: x mandatory; 

}

.image-row {
    display: flex; 
}

.row-design-image{
    display: flex;
    width: 70%;
    scroll-snap-align: center;  
}

.garment-tabs {
    background-color: transparent; 
    border-radius: 8px;
    display: flex;
    justify-content: center;
}
  
.garment-tabs .MuiTabs-indicator {
    background-color: var(--LIGHT-BLUE);
}

.garment-tab.Mui-selected {
    font-family: var(--PROXIMA-NOVA-BOLD);
    font-size: 1.4rem;
}

.garment-tab:not(.Mui-selected) {
    font-family: var(--PROXIMA-NOVA-BOLD);
    font-size: 1.4rem;
    color: var(--LIGHT-BLUE);
    opacity: 0.5;
}

.garment-tab:hover {
    font-family: var(--PROXIMA-NOVA-BOLD);
    font-size: 1.4rem;
    color: var(--LIGHT-BLUE); 
    opacity: 1;
  }

.back-button-bar {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}
.back-button-text{
    font-family: var(--PROXIMA-NOVA-BOLD);
    font-size: 1.8rem;
    color: var(--CLUBBIE-WHITE);
    margin-left: 10px;
}