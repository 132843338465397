@import '../utils/styles.css';


.saved-designs-page {
    display: flex;
    height: 100vh;
    justify-content: space-between;
    flex-direction: column;
    background-color: var(--APP-BACKGROUND);
}

.saved-designs {
    display: flex;
    justify-content: center;
    height: 85vh;
    padding-top: 10px;
    overflow-y: auto;
}

.saved-design {
    display: flex;
    margin-bottom: 35px;
}

.saved-designs-back-button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 10px;
}

.nothing-saved-msg {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--CLUBBIE-WHITE);
    font-size: 2.5rem;
    font-family: var(--PROXIMA-NOVA-SEMIBOLD);
}

.saved-design-image {
    display: flex; 
    width: 100%;
    height: auto;
    aspect-ratio: 9/10;
    object-fit: cover;
    overflow: hidden;
}

@media (max-width: 768px){
    .saved-design {
        border-bottom: 1px solid var(--LIGHT-BLUE);
    }
}

