@import '../utils/styles.css';

.bottom-buttons-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;    
    margin-bottom: 20px;
}

.bottom-button-mobile {
    border: 1px solid var(--LIGHT-BLUE);
    background-color: var(--LIGHT-BLUE);
    color: var(--CLUBBIE-BLACK);
    font-size: 1.2rem;
    font-family: var(--PROXIMA-NOVA-BOLD);
    border-radius: 8px;
    margin-bottom: 5px;
    width: 200px;
    height: 40px;
    display: flex;
    justify-content: center;
    padding-top: 5px;
    text-transform: uppercase;

}

.bottom-button-mobile:hover {
    opacity: 0.5
}

