@import '../utils/styles.css';

.cart-page {
    display: flex; 
    min-height: 100vh; 
    min-width: 100vw;
    flex-direction: column;
    background-color: var(--APP-BACKGROUND);
}

.item-title {
    color: var(--CLUBBIE-WHITE);
    font-size: 2.5rem;
    font-family: var(--PROXIMA-NOVA-BOLD) !important;
}

.item-quantity-heading {
    color: var(--CLUBBIE-WHITE);
    font-size: 1.5rem;
    font-family: var(--PROXIMA-NOVA-BOLD);
}

.item-quantity {
    display: inline-block;
    min-width: 40px; 
    max-width: 40px;
    text-align: center;
}

.decrease-quantity {
    cursor: pointer;
    margin-left: 15px;
}

.increase-quantity {
    cursor: pointer;
}

.delete-item {
   cursor: pointer;
   margin-left: 15px;
   color: var(--CORAL-RED)
}

.delete-item.socks {
    margin-left: 0px;
}

.empty-cart-msg {
    color: var(--CLUBBIE-WHITE);
    font-size: 2.5rem;
    font-family: var(--PROXIMA-NOVA-SEMIBOLD);
}

.filled-cart-msg {
    display: flex;
    height: 10vh;
    color: var(--LIGHT-BLUE);
    font-size: 4.5rem;
    font-family: var(--HEATERS);
    justify-content: space-between;
    align-items: center;
}

.filled-cart-msg-mobile{
    display: flex;
    height: 10vh;
    color: var(--LIGHT-BLUE);
    font-size: 4.5rem;
    font-family: var(--HEATERS);
    justify-content: flex-start;
    align-items: center;
}

.back-button-cart {
    width: 5%;
    margin-right: 30px;
    margin-bottom: 10px;
}

.cart-bottom-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    width: 100%;
}


.back-order-buttons {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: 20px;
}

.design-team-note {
    display: flex;
    color: var(--CLUBBIE-WHITE);
    font-size: 1rem;
    font-family: var(--PROXIMA-NOVA-SEMIBOLD);
    max-width: 50%;  
}

.design-team-note.mobile {
    max-width: 80%;
    text-align: center;
}



.item-total {
    margin-top: 10px;
    color: var(--LIGHT-BLUE);
    font-size: 1.5rem;
    font-family: var(--PROXIMA-NOVA-BOLD);
}

.cart-total {
    color: var(--LIGHT-BLUE);
    font-size: 2.5rem;
    font-family: var(--PROXIMA-NOVA-BOLD);
}

.cart-total-figure {
    color: var(--CLUBBIE-WHITE);
    font-size: 2.5rem;
    font-family: var(--PROXIMA-NOVA-BOLD);
}

.cost-order {
    display: flex;
}

.cart-item-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.cart-item-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-bottom: 1px solid var(--LIGHT-BLUE);
    padding-top: 20px;
}

.size-chart-button-mobile {
    display: flex;
    justify-content: center;
}

.place-order-button-mobile {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.cart-design-image-mobile{
    display: flex; 
    width: 80%;
}