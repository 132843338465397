@import '../utils/styles.css';

.stage {
    position: relative;
    width: 100%;
}

.rotate-button-left {
    width: 6%;
    position: absolute;
    top: 50%;
    left: 2%;
}

.rotate-button-right {
    width: 6%;
    position: absolute;
    top: 50%;
    right: 2%;
    transform: scaleX(-1);
}

.reset-rotation-button {
    width: 6%;
    position: absolute;
    top: 2%;
    right: 2%;
}