.spinner {
    display: inline-block;
    width: 50px;
    height: 50px;
    /* margin-top: 200px; */
  }
  
  .spinner img {
    width: 100%;
    height: 100%;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }


.design-image-loader {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    height: 150px;
    z-index: 1; 
    top: 50%; 
    left: 50%; 
    transform: translate(-50%, -50%); 
}