@import '../utils/styles.css';

.top-buttons {
    position: absolute;
    top: 5%;
    right: 1.5%;
    display: flex;
    flex-direction: column;
}

.top-button {
    border: 1px solid var(--LIGHT-BLUE);
    background-color: var(--LIGHT-BLUE);
    color: var(--CLUBBIE-BLACK);
    font-size: 1.25rem;
    font-family: var(--PROXIMA-NOVA-BOLD);
    border-radius: 8px;
    margin-bottom: 5px;
    width: 200px;
    height: 40px;
    display: flex;
    justify-content: center;
    padding-top: 5px;
}

.top-button:hover {
    opacity: 0.5
}


.top-buttons.selection.bottom-center {
    position:relative;
    display: flex;
    flex-direction: column;
    right: 0%;
    top: 0%;
    margin-top: 20px;
}
